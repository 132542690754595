
import { capitalize, defineComponent, onMounted, ref } from 'vue'
import HomeworkDeliveriesModal from '@/components/modals/HomeworkDeliveriesModal.vue'
import { CourseAPI } from '@/api/course.api'
import { MenuItem } from 'primevue/menuitem'
import {
  IStaffCourseSummary,
  IStudentCourseSummary
} from '@/models/course/interfaces'
import { FormatDates } from '@/models/enums'
import { injectStrict } from '@/utils/injectStrict'
import { DateTime } from 'luxon'
import { PersonKey } from '../keys'
import { useI18n } from 'vue-i18n'
import { truncate } from '@/utils/math'

export default defineComponent({
  components: {
    HomeworkDeliveriesModal
  },
  setup () {
    const { t } = useI18n()
    const loading = ref(true)
    const person = injectStrict(PersonKey)
    const activeIndex = ref(0)
    const showHomeworkModal = ref(false)
    const menuItems = ref<MenuItem[]>([
      {
        label: 'Estudiante',
        command () {
          activeIndex.value = 0
        }
      },
      {
        label: 'Staff',
        command () {
          activeIndex.value = 1
        }
      }
    ])
    const asStudent = ref<IStudentCourseSummary[]>([])
    const asStaff = ref<IStaffCourseSummary[]>([])
    const selectedStudentCourseSummary = ref<IStudentCourseSummary | null>(null)
    onMounted(async () => {
      if (person.value) {
        loading.value = true
        const result = await CourseAPI.findByPersonId(person.value?.id)
        asStudent.value = result.student
        asStaff.value = result.staff
        loading.value = false
      }
    })

    return {
      asStudent,
      asStaff,
      activeIndex,
      loading,
      menuItems,
      showHomeworkModal,
      t,
      capitalize,
      truncate,
      selectedStudentCourseSummary,
      setShowHomeworkModal (courseSummary: IStudentCourseSummary) {
        showHomeworkModal.value = !showHomeworkModal.value
        selectedStudentCourseSummary.value = courseSummary
      },
      formatDate: (date: string) =>
        DateTime.fromISO(date).toFormat(FormatDates.LatinAmerica)
    }
  }
})
