import { HomeworkCollection, HomeworkType } from '../academic-definitions/enums'

export enum HomeworkDeliveryStatus {
  SENT = 'sent',
  DELIVERED = 'delivered',
  CLAIMED_FOR_FORWARDING = 'claimedForwarding',
  FORWARDED = 'forwarded',
  APPROVED = 'approved',
  DISAPPROVED = 'disapproved',
  EXPIRED = 'expired',
  PENDING_DELIVERY = 'pendingDelivery',
  NOT_RESOLVED = 'notResolved'
}

export enum HomeworkDeliverySoftGrade {
  High = 10,
  Medium = 7,
  Low = 4
}

export interface IHomeworkPlanSummary {
  name: string
  type: HomeworkType
  id: string
}

export interface IClassPlanSummary {
  name: string
  id?: string
}
export interface IHomeworkDeliverySummary {
  courseId: string
  studentId: string
  classPlan: IClassPlanSummary
  homeworkPlan: IHomeworkPlanSummary
  status: HomeworkDeliveryStatus
  enabled: boolean
  grade?: number
  locked?: boolean
  id: string
  homeworkCollection: HomeworkCollection | null
}

export interface Status {
  approved: boolean
  disapproved: boolean
  reviewed: boolean
  hasMoreThanOneChange: boolean
  highScore: boolean
  lowScore: boolean
  mediumScore: boolean
  fromSoftScore?: string
  toSoftScore?: string
}

export interface UpdateProjectStatusResponseDto {
  _id: string
  status: Status
  score?: number
}

export interface IUpdateHomeworkDeliveryData {
  studentId: string
  courseId: string
  homeworkId: string
  status?: string // TODO tipar
  softScore?: string // TODO tipar
  score?: number
  homeworkType: HomeworkType
  homeworkName: string
}

export interface IUpdateLockPropertyHomeworkDelivery {
  studentId: string
  courseId: string
  homeworkId: string
  unlock: boolean
}

export interface IDeleteHomeworkDelivery {
  studentId: string
  courseId: string
}
