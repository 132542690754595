
import {
  capitalize,
  ComponentPublicInstance,
  computed,
  defineComponent,
  PropType,
  ref,
  watch
} from 'vue'
import { HomeworksApi } from '@/api/homeworks.api'
import {
  HomeworkDeliverySoftGrade,
  HomeworkDeliveryStatus,
  IHomeworkDeliverySummary,
  IUpdateHomeworkDeliveryData
} from '@/models/homeworks'
import { IStudentCourseSummary } from '@/models/course/interfaces'
import {
  HomeworkStatus,
  HomeworkType,
  HomeworkCollection
} from '@/models/academic-definitions/enums'
import Menu from 'primevue/menu'
import { useToastService } from '@/hooks/useToastService'
import { useI18n } from 'vue-i18n'
import { useConfirm } from 'primevue/useconfirm'
import { MenuItem } from 'primevue/menuitem'

export default defineComponent({
  props: {
    visible: {
      type: Boolean
    },
    courseSummary: {
      type: Object as PropType<IStudentCourseSummary>,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    courseId: {
      type: String,
      default: null
    },
    personId: {
      type: String,
      default: null
    },
    homeworkType: {
      type: Object as PropType<HomeworkType>,
      default: null
    },
    homeworkStatus: {
      type: Object as PropType<HomeworkStatus>,
      default: null
    }
  },
  emits: ['update:visible'],
  setup (props) {
    const courseId = computed(() => props.courseId)
    const personId = computed(() => props.personId)
    const homeworkType = computed(() => props.homeworkType)
    const homeworkStatus = computed(() => props.homeworkStatus)
    const oldData = ref<HomeworkDeliveryStatus | number | null>(null)
    const updateData = ref<IUpdateHomeworkDeliveryData | null>(null)
    const editingDelivery = ref<string | null>(null)
    const courseSummary = computed(() => props.courseSummary)
    const visibleProp = computed(() => props.visible)
    const actionsMenu = ref<ComponentPublicInstance<Menu> | null>(null)
    const isVisible = ref(visibleProp.value)
    const loading = ref(true)
    const summaries = ref<IHomeworkDeliverySummary[]>([])
    const toast = useToastService()
    const { t } = useI18n()
    const actionsOptions = ref<MenuItem[]>([{}])
    const preIntegratorValues = new Map([
      ['lowScore', 4],
      ['mediumScore', 7],
      ['highScore', 10]
    ])
    const preIntegratorStatus = new Map([
      [4, 'Bajo'],
      [7, 'Correcto'],
      [10, 'Óptimo']
    ])
    const confirm = useConfirm()
    const confirmDeleteDelivery = () =>
      new Promise<boolean>(resolve =>
        confirm.require({
          group: 'global',
          message: '¿Está seguro que desea eliminar la entrega?',
          header: 'Importante',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          accept: () => resolve(true),
          reject: () => resolve(false)
        })
      )
    watch([visibleProp], async () => {
      isVisible.value = visibleProp.value
      if (isVisible.value) {
        try {
          loading.value = true
          editingDelivery.value = null
          oldData.value = null
          updateData.value = null
          if (courseSummary.value) {
            summaries.value = await HomeworksApi.findDeliveriesSummaries(
              courseSummary.value?.id,
              courseSummary.value?.personId
            )
          } else if (courseId.value && personId.value) {
            const homeworkDeliveriesPromise = HomeworksApi.findDeliveriesSummaries(
              courseId.value,
              personId.value,
              homeworkType.value,
              homeworkStatus.value
            )
            if (homeworkType.value === HomeworkType.Final) {
              const [
                homeworkDeliveries,
                homeworkUndeliveries
              ] = await Promise.all([
                homeworkDeliveriesPromise,
                HomeworksApi.findDeliveriesSummaries(
                  courseId.value,
                  personId.value,
                  homeworkType.value,
                  HomeworkStatus.PendingDelivery
                )
              ])
              summaries.value = homeworkDeliveries.concat(homeworkUndeliveries)
            } else {
              summaries.value = await homeworkDeliveriesPromise
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          loading.value = false
        }
      }
    })

    const cancelHomeworkEdition = (): void => {
      summaries.value = summaries.value.map(s => {
        if (updateData.value) {
          if (s.id === updateData.value.homeworkId && oldData.value) {
            if (typeof oldData.value === 'string') {
              s.status = oldData.value
            } else if (typeof oldData.value === 'number') {
              s.grade = oldData.value
            }
          }
        }
        return s
      })
      oldData.value = null
      editingDelivery.value = null
      updateData.value = null
    }

    const saveHomework = async (): Promise<void> => {
      if (!updateData.value) {
        oldData.value = null
        editingDelivery.value = null
        updateData.value = null
        return
      }
      try {
        loading.value = true
        const res = await HomeworksApi.updateHomeworkDelivery(updateData.value)
        summaries.value = summaries.value.map(s => {
          if (s.id === res._id) {
            if (res.status.approved) s.status = HomeworkDeliveryStatus.APPROVED
            else if (res.status.disapproved) {
              s.status = HomeworkDeliveryStatus.DISAPPROVED
            } else if (res.status.toSoftScore) {
              s.grade = preIntegratorValues.get(res.status.toSoftScore)
            }
          }
          return s
        })
        let alertMessage = ''
        if (updateData.value.homeworkType === HomeworkType.Regular) {
          alertMessage = `"${updateData.value.homeworkName}" ${t(
            `api.homework-delivery-status.${updateData.value.status}`
          )}`
        } else {
          alertMessage = `"${
            updateData.value.homeworkName
          }" ${preIntegratorStatus.get(updateData.value.score as number)}`
        }
        toast.showSuccessfullyDone(alertMessage)
      } catch (error) {
        summaries.value = summaries.value.map(s => {
          if (updateData.value) {
            if (s.id === updateData.value.homeworkId && oldData.value) {
              if (typeof oldData.value === 'string') s.status = oldData.value
              else if (typeof oldData.value === 'number') {
                s.grade = oldData.value
              }
            }
          }
          return s
        })
        toast.showError(
          `Error al actualizar la calificación de "${updateData.value.homeworkName}"`
        )
      } finally {
        oldData.value = null
        editingDelivery.value = null
        updateData.value = null
        loading.value = false
      }
    }
    return {
      isVisible,
      summaries,
      loading,
      actionsMenu,
      editingDelivery,
      HomeworkDeliveryStatus,
      HomeworkType,
      HomeworkCollection,
      cancelHomeworkEdition,
      saveHomework,
      toggleActionsMenu (e: MouseEvent, data: IHomeworkDeliverySummary) {
        actionsMenu.value && actionsMenu.value.toggle(e)
        const EDIT = {
          label: 'Editar',
          icon: 'pi pi-pencil',
          command: () => {
            editingDelivery.value = data.id
          }
        }
        const LOCK = {
          label: 'Bloquear',
          icon: 'pi pi-lock',
          command: async () => {
            try {
              await HomeworksApi.updateLockHomeworkDelivery({
                studentId: data.studentId,
                courseId: data.courseId,
                homeworkId: data.id,
                unlock: false
              })
              summaries.value = summaries.value.map(s => {
                if (s.id === data.id) {
                  s.locked = true
                  if (typeof oldData.value === 'string') {
                    s.status = oldData.value
                  } else if (typeof oldData.value === 'number') {
                    s.grade = oldData.value
                  } else s.status = data.status
                }
                return s
              })
            } catch {
              summaries.value = summaries.value.map(s => {
                if (updateData.value) {
                  if (s.id === updateData.value.homeworkId && oldData.value) {
                    if (typeof oldData.value === 'string') {
                      s.status = oldData.value
                    } else if (typeof oldData.value === 'number') {
                      s.grade = oldData.value
                    } else s.status = data.status
                  }
                }
                return s
              })
              toast.showError(`Error al bloquear "${data.homeworkPlan.name}"`)
            } finally {
              oldData.value = null
              editingDelivery.value = null
              updateData.value = null
            }
          }
        }
        const DELETE = {
          label: 'Eliminar',
          icon: 'pi pi-trash',
          command: async () => {
            try {
              let enabled = true
              if (await confirmDeleteDelivery()) {
                await HomeworksApi.deleteHomeworkDelivery(data.id, {
                  studentId: data.studentId,
                  courseId: data.courseId
                })
                enabled = false
              }
              summaries.value = summaries.value.map(s => {
                if (s.id === data.id) {
                  if (typeof oldData.value === 'string') {
                    s.status = oldData.value
                  } else if (typeof oldData.value === 'number') {
                    s.grade = oldData.value
                  } else s.status = data.status
                  return { ...s, enabled: enabled }
                } else {
                  return s
                }
              })
            } catch {
              summaries.value = summaries.value.map(s => {
                if (updateData.value) {
                  if (s.id === updateData.value.homeworkId && oldData.value) {
                    if (typeof oldData.value === 'string') {
                      s.status = oldData.value
                    } else if (typeof oldData.value === 'number') {
                      s.grade = oldData.value
                    } else s.status = data.status
                  }
                }
                return s
              })
              toast.showError(`Error al eliminar "${data.homeworkPlan.name}"`)
            } finally {
              oldData.value = null
              editingDelivery.value = null
              updateData.value = null
            }
          }
        }

        const RECOVER = {
          label: 'Recuperar',
          icon: 'pi pi-replay',
          command: async () => {
            try {
              await HomeworksApi.deleteHomeworkDelivery(data.id, {
                studentId: data.studentId,
                courseId: data.courseId
              })
              summaries.value = summaries.value.map(s =>
                s.id === data.id ? { ...s, enabled: true } : s
              )
            } catch {
              summaries.value = summaries.value.map(s => {
                if (updateData.value) {
                  if (s.id === updateData.value.homeworkId && oldData.value) {
                    if (typeof oldData.value === 'string') {
                      s.status = oldData.value
                    } else if (typeof oldData.value === 'number') {
                      s.grade = oldData.value
                    } else s.status = data.status
                  }
                }
                return s
              })
              toast.showError(`Error al eliminar "${data.homeworkPlan.name}"`)
            } finally {
              oldData.value = null
              editingDelivery.value = null
              updateData.value = null
            }
          }
        }
        const UNLOCK = {
          label: 'Desbloquear',
          icon: 'pi pi-unlock',
          command: async () => {
            try {
              await HomeworksApi.updateLockHomeworkDelivery({
                studentId: data.studentId,
                courseId: data.courseId,
                homeworkId: data.id,
                unlock: true
              })
              summaries.value = summaries.value.map(s => {
                if (s.id === data.id) {
                  s.locked = false
                }
                return s
              })
            } catch {
              summaries.value = summaries.value.map(s => {
                if (updateData.value) {
                  if (s.id === updateData.value.homeworkId && oldData.value) {
                    if (typeof oldData.value === 'string') {
                      s.status = oldData.value
                    } else if (typeof oldData.value === 'number') {
                      s.grade = oldData.value
                    } else s.status = data.status
                  }
                }
                return s
              })
              toast.showError(
                `Error al desbloquear "${data.homeworkPlan.name}"`
              )
            } finally {
              oldData.value = null
              editingDelivery.value = null
              updateData.value = null
            }
          }
        }
        if (data.locked) {
          actionsOptions.value = [UNLOCK]
        } else if (!data.enabled) {
          actionsOptions.value = [RECOVER]
        } else if (data.status === HomeworkDeliveryStatus.DELIVERED) {
          actionsOptions.value = [EDIT]
        } else {
          actionsOptions.value = [EDIT, LOCK, DELETE] as MenuItem[]
        }
      },
      async handleDropdownStatusChange (
        e: {
          originalEvent: Event
          value: HomeworkDeliveryStatus | number
        },
        data: IHomeworkDeliverySummary
      ) {
        const { courseId, studentId, id, homeworkPlan } = data
        updateData.value = {
          studentId,
          courseId,
          homeworkId: id,
          homeworkType: homeworkPlan.type,
          homeworkName: homeworkPlan.name
        }
        if (
          e.value === HomeworkDeliveryStatus.APPROVED ||
          e.value === HomeworkDeliveryStatus.DISAPPROVED
        ) {
          if (!oldData.value) oldData.value = data.status
          updateData.value.status = e.value
          data.status = e.value
        } else if (typeof e.value === 'number') {
          updateData.value.score = e.value
          if (!oldData.value) oldData.value = data.grade || null
          data.grade = e.value
          if (homeworkPlan.type === HomeworkType.PreFinal) {
            if (e.value === 10) updateData.value.softScore = 'highScore'
            else if (e.value === 7) updateData.value.softScore = 'mediumScore'
            else if (e.value === 4) updateData.value.softScore = 'lowScore'
          }
        }
      },
      statusValue (data: IHomeworkDeliverySummary) {
        const opts = {
          [HomeworkType.Final]: data.grade,
          [HomeworkType.PreFinal]: data.grade,
          [HomeworkType.Regular]: data.status,
          [HomeworkType.Complementary]: data.status
        }
        return opts[data.homeworkPlan.type] || data.status
      },
      stateOptions (
        homeworkType: HomeworkType
      ): { label: string; value: string | number }[] {
        if (homeworkType === HomeworkType.PreFinal) {
          return [
            {
              label: 'Óptimo',
              value: HomeworkDeliverySoftGrade.High
            },
            {
              label: 'Correcto',
              value: HomeworkDeliverySoftGrade.Medium
            },
            {
              label: 'Bajo',
              value: HomeworkDeliverySoftGrade.Low
            }
          ]
        } else if (homeworkType === HomeworkType.Final) {
          const items: { label: string; value: string | number }[] = []
          for (let i = 1; i <= 10; i++) {
            items.push({ value: i, label: i.toString() })
          }
          items.push({ value: -1, label: 'Caducó' })
          return items
        } else {
          return [
            {
              label: capitalize(
                t(
                  `api.homework-delivery-status.${HomeworkDeliveryStatus.APPROVED}`
                )
              ),
              value: HomeworkDeliveryStatus.APPROVED
            },
            {
              label: capitalize(
                t(
                  `api.homework-delivery-status.${HomeworkDeliveryStatus.DISAPPROVED}`
                )
              ),
              value: HomeworkDeliveryStatus.DISAPPROVED
            }
          ]
        }
      },
      actionsOptions
    }
  }
})
