export enum Difficulty {
  Alta = 'Alta',
  Moderada = 'Moderada',
  Baja = 'Baja'
}

export enum TypeModality {
  regular = 'regular',
  intensive = 'intensive'
}

export enum SyllabusTarget {
  b2b = 'b2b',
  b2c = 'b2c'
}

export enum ClientsB2b {
  Technisys = 'technisys',
  Falabella = 'falabella'
}

export enum EducationPlanType {
  CoursePlan = 'CoursePlan',
  CareerPlan = 'CareerPlan'
}

export enum EducationPlanWWWPaths {
  CareerPlan = 'https://www.coderhouse.com/online/', // De momento son iguales
  CoursePlan = 'https://www.coderhouse.com/online/'
}

export enum HomeworkCollection {
  HomeworkDelivery = 'homeworkDelivery',
  Project = 'project'
}

export enum HomeworkType {
  Final = 'final',
  Regular = 'regular',
  Complementary = 'complementary',
  PreFinal = 'preFinal',
}

export enum HomeworkStatus {
  Approved = 'approved',
  OnHold = 'delivered',
  PendingDelivery ='pendingDelivery',
}

export enum EducationPlanConfiguration {
  LaptopRequired = 'laptopRequired',
  Earlybird = 'earlybird',
  Workshop = 'workshop',
  Playbook = 'playbook',
  Free = 'free',
  Pack = 'pack',
  Service = 'service',
  Remote = 'remote',
  Offline = 'offline',
  Prerecorded = 'prerecorded',
  Career = 'career',
  Partner = 'partner'
}

export enum HomeworkExpiredTerm {
  Regular = 7
}
