import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    modal: true,
    header: 
      _ctx.courseSummary
        ? `Entregas: ${_ctx.courseSummary.numberId} - ${_ctx.courseSummary.name}`
        : 'Entregas'
    ,
    showHeader: _ctx.showHeader,
    dismissableMask: true,
    style: {"width":"70vw","height":"90%"},
    contentStyle: "height: 100%",
    visible: _ctx.isVisible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isVisible = $event)),
    onHide: _cache[1] || (_cache[1] = $event => _ctx.$emit('update:visible', false))
  }, _createSlots({
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        loading: _ctx.loading,
        value: _ctx.summaries,
        scrollable: true,
        scrollHeight: "flex"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            style: {"flex":"1 1 0"},
            field: "classPlan.name",
            header: "Clase"
          }),
          _createVNode(_component_Column, {
            style: {"flex":"1 1 0"},
            field: "homeworkPlan.name",
            header: "Desafío"
          }),
          _createVNode(_component_Column, {
            style: {"flex":"0.5 1 0"},
            header: "Estado"
          }, {
            body: _withCtx(({ data: { homeworkPlan: { type } }, data }) => [
              _createVNode(_component_Dropdown, {
                onChange: $event => _ctx.handleDropdownStatusChange($event, data),
                modelValue: _ctx.statusValue(data),
                options: _ctx.stateOptions(type),
                placeholder: 
              data.enabled === false
                ? 'Eliminado'
                : data.locked
                ? 'Bloqueado'
                : data.status === _ctx.HomeworkDeliveryStatus.PENDING_DELIVERY
                ? 'Faltante'
                : 'Pendiente'
            ,
                disabled: 
              _ctx.editingDelivery !== data.id || data.locked || data.id === null
            ,
                optionLabel: "label",
                optionValue: "value"
              }, null, 8, ["onChange", "modelValue", "options", "placeholder", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { style: {"flex":"0.2 1 0"} }, {
            body: _withCtx(({ data }) => [
              (
              (data.homeworkCollection && data.homeworkCollection === _ctx.HomeworkCollection.Project) &&
              (
                data.homeworkPlan.type === _ctx.HomeworkType.Complementary ||
                data.homeworkPlan.type === _ctx.HomeworkType.Final && data.grade && data.grade !== -1 ||
                (data.homeworkPlan.type === _ctx.HomeworkType.PreFinal && data.grade) ||
                (
                  data.homeworkPlan.type === _ctx.HomeworkType.Regular &&
                  ![
                    _ctx.HomeworkDeliveryStatus.DELIVERED,
                    _ctx.HomeworkDeliveryStatus.PENDING_DELIVERY
                  ].includes(data.status)
                )
              )
            )
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    onClick: $event => _ctx.toggleActionsMenu($event, data),
                    class: "p-button-text",
                    icon: "pi pi-ellipsis-h",
                    "aria-haspopup": "true",
                    "aria-controls": "actions_menu",
                    disabled: _ctx.editingDelivery && _ctx.editingDelivery !== data.id
                  }, null, 8, ["onClick", "disabled"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Menu, {
                id: "actions_menu",
                ref: "actionsMenu",
                model: _ctx.actionsOptions,
                popup: true
              }, null, 8, ["model"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading", "value"])
    ]),
    _: 2
  }, [
    (_ctx.editingDelivery)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_Button, {
              label: "Cancelar",
              onClick: _ctx.cancelHomeworkEdition
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              label: "Guardar",
              onClick: _ctx.saveHomework
            }, null, 8, ["onClick"])
          ])
        }
      : undefined
  ]), 1032, ["header", "showHeader", "visible"]))
}