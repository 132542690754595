import {
  HomeworkStatus,
  HomeworkType
} from '@/models/academic-definitions/enums'
import {
  IDeleteHomeworkDelivery,
  IHomeworkDeliverySummary,
  IUpdateHomeworkDeliveryData,
  IUpdateLockPropertyHomeworkDelivery,
  UpdateProjectStatusResponseDto
} from '@/models/homeworks'
import { Api } from './api'

const baseURL = process.env.ACADEMIC_API_ENDPOINT
export class HomeworksApi {
  static async findDeliveriesSummaries(
    courseId: string,
    personId: string,
    type?: HomeworkType,
    status?: HomeworkStatus
  ): Promise<IHomeworkDeliverySummary[]> {
    return Api.get<IHomeworkDeliverySummary[]>(
      `${baseURL}/v1/student/${personId}/course/${courseId}/homework-delivery-summaries`,
      { ...(type && { type }), ...(status && { status }) }
    )
  }

  static async updateHomeworkDelivery(
    data: IUpdateHomeworkDeliveryData
  ): Promise<UpdateProjectStatusResponseDto> {
    return Api.put<UpdateProjectStatusResponseDto>(
      `${baseURL}/v1/projects`,
      data
    )
  }

  static async updateLockHomeworkDelivery(
    data: IUpdateLockPropertyHomeworkDelivery
  ): Promise<void> {
    return Api.put<void>(`${baseURL}/v1/projects/lock`, data)
  }

  static async deleteHomeworkDelivery(
    homeworkId: string,
    data: IDeleteHomeworkDelivery
  ): Promise<void> {
    return Api.del<void>(`${baseURL}/v1/projects/${homeworkId}`, data)
  }

  static async getHomeworkUnDelivery(
    courseId: string,
    studentId: string
  ): Promise<IHomeworkDeliverySummary> {
    return Api.get<IHomeworkDeliverySummary>(
      `${baseURL}/v1/modules/course/${courseId}/student/${studentId}`
    )
  }
}
